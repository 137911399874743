import React from "react";
import portfolio1 from "./../components/assets/img/portfolio1.jpg";
import portfolio2 from "./../components/assets/img/portfolio2.jpg";
import HQ from "./../components/assets/img/porfolioImg/HQ.png";
import MSI from "./../components/assets/img/porfolioImg/MSI.png";
import UAP from "./../components/assets/img/porfolioImg/UAP.png";
import MBY from "./../components/assets/img/porfolioImg/MBY.png";
import FA1 from "./../components/assets/img/porfolioImg/FA1.PNG";
import OC from "./../components/assets/img/porfolioImg/OC.png";
import BW from "./../components/assets/img/porfolioImg/BW.png";
import QCU from "./../components/assets/img/porfolioImg/QCU.png";
import team1 from "./../components/assets/img/teams/team1.jpg";
import team2 from "./../components/assets/img/teams/team2.jpg";
import team3 from "./../components/assets/img/teams/team3.jpg";
import team4 from "./../components/assets/img/teams/team4.jpg";
import president from "./../components/assets/img/teams/president.jpg";
import vice from "./../components/assets/img/teams/vice-president.jpg";
import itmanager from "./../components/assets/img/teams/itmanager.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import GLightbox from "glightbox";
import { Link } from "react-router-dom";

function Portfolio() {
  const glightbox = GLightbox({
    selector: ".glightbox",
  });

  const portfolioLightbox = GLightbox({
    selector: ".portfolio-lightbox",
  });

  return (
    <>
      <section id="portfolio" className="portfolio">
        <Container data-aos="fade-up">
          <div class="section-title">
            <h2>Portfolio</h2>
            <p className="sub-title">Ongoing Project</p>
          </div>

          <Row
            classname="portfolio-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="row d-flex justify-content-evenly">
              <Col lg={4} md={6} classname="portfolio-item filter-app">
                <div class="portfolio-img">
                  <a
                    href="https://ems.mavericksolutionsinc.ph/login"
                    target="__blank"
                  >
                    <img src={MSI} className="img-fluid" alt="" />
                  </a>
                </div>
                <div class="portfolio-info projectinfo"></div>

                <Link as={Link} to="/project2-details">
                  <h4 class="projectname">
                    MSI-EMS: Enterprise Management System
                  </h4>
                </Link>

                <p>
                  MSI-EMS: Enterprise Management System
                  (ems.mavericksolutionsinc.ph) Main Use Case: Portable
                  Management System for Contractors Client: Maverick Solutions
                  Inc.
                </p>
              </Col>

              <Col lg={4} md={6} classname="portfolio-item filter-app">
                <div class="portfolio-img">
                  <a href="https://www.uapqccentral.xyz/login" target="__blank">
                    <img src={UAP} className="img-fluid" alt="" />
                  </a>
                </div>
                <div class="portfolio-info projectinfo"></div>

                <Link as={Link} to="/project3-details">
                  <h4 class="projectname">UAP QC Central Website Portal</h4>
                </Link>

                <p>
                  UAP QC Central Website Portal (www.uapqccentral.xyz) Main Use
                  Case: Payment Platform for chapter dues Client: UAP QC Central
                  Chapter
                </p>
              </Col>
            </div>
            {/* <Col lg={4} md={6} classname="portfolio-item filter-app">
              <div class="portfolio-img">
                <a href="https://www.qube.homeqube.com/" target="__blank">
                  <img src={HQ} className="img-fluid" alt="" />
                </a>
              </div>

              <div class="portfolio-info projectinfo"></div>

              <Link as={Link} to="/project1-details">
                <h4 class="projectname">Homeqube Website Ecosystem</h4>
              </Link>

              <p>
                Main Use Case: Ecosystem of Websites for Home Building Client:
                Homeqube Pte. Ltd. (Singapore)
              </p>
            </Col> */}

            <div class="section-title">
              <p className="sub-title">Prospect Projects</p>
            </div>
            <Col lg={4} md={6} classname="portfolio-item filter-app">
              <div class="portfolio-img">
                <img src={MBY} className="img-fluid" alt="" />
              </div>
              <div class="portfolio-info projectinfo"></div>

              <Link as={Link} to="/project4-details">
                <h4 class="projectname">Made By You Shop</h4>
              </Link>

              <p>Main Use Case: Shirt E-commerce Website</p>
            </Col>

            <Col lg={4} md={6} classname="portfolio-item filter-app">
              <div class="portfolio-img">
                <img src={FA1} className="img-fluid" alt="" />
              </div>
              <div class="portfolio-info projectinfo"></div>

              <Link as={Link} to="/project5-details">
                <h4 class="projectname">Filumin Art</h4>
              </Link>

              <p>Main Use Case: Art Commission Selling Website</p>
            </Col>

            <Col lg={4} md={6} classname="portfolio-item filter-app">
              <div class="portfolio-img">
                <img src={BW} className="img-fluid" alt="" />
              </div>
              <div class="portfolio-info projectinfo"></div>

              <Link as={Link} to="/project6-details">
                <h4 class="projectname">Black Wings</h4>
              </Link>

              <p>Main Use Case: Shoe E-commerce Website</p>
            </Col>

            <Col lg={4} md={6} classname="portfolio-item filter-app">
              <div class="portfolio-img">
                <img src={OC} className="img-fluid" alt="" />
              </div>
              <div class="portfolio-info projectinfo"></div>

              <Link as={Link} to="/project7-details">
                <h4 class="projectname">Octagon</h4>
              </Link>

              <p>Main Use Case: Recruitment Management System</p>
            </Col>

            <Col lg={4} md={6} classname="portfolio-item filter-app">
              <div class="portfolio-img">
                <img src={QCU} className="img-fluid" alt="" />
              </div>
              <div class="portfolio-info projectinfo"></div>

              <Link as={Link} to="/project8-details">
                <h4 class="projectname">Quezon City University Apply</h4>
              </Link>

              <p>Main Use Case: Enrollment Management System</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section id="team" className="team section-bg">
        <div className="mx-5">
          <div className="section-title">
            <h2>Team</h2>
          </div>
          <Row>
            <Col lg={6}>
              <div
                className="scon member d-flex align-items-start mb-5"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="pic">
                  <img src={president} className="pic-team" alt="" />
                </div>

                <div className="member-info">
                  <h4 className="mb-4">Engr. Kristoffer Dave A. Tabong</h4>
                  <p className="">President</p>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <div
                className="scon member d-flex align-items-start mb-5"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="pic">
                  <img src={vice} className="pic-team" alt="" />
                </div>

                <div className="member-info">
                  <h4 className="mb-4">Arch. Elise Sophia Francisco-Tabong</h4>

                  <p className="">Vice President</p>
                </div>
              </div>
            </Col> */}

            {/* <Col md={4}>
             
            </Col> */}
            {/* <div className="d-flex justify-content-around">
              <div
                className="scon member d-flex align-items-start mb-5"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="pic">
                  <img src={itmanager} className="pic-team" alt="" />
                </div>

                <div className="member-info">
                  <h4 className="mb-4">Mel Bryan Flores</h4>

                  <p className="">IT Manager</p>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </section> */}
    </>
  );
}

export default Portfolio;
