import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Contact() {
  return (
    <>
      <section id="contact" class="contact">
        <Container data-aos="fade-up">
          <div class="section-title">
            <h2>Contact</h2>
            {/* <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p> */}
          </div>

          <Row>
            <Col lg={5} classname="d-flex align-items-stretch">
              <div class="info">
                {/* <div class="address"> */}
                  {/* Icon */}
                  {/* <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>81 DETROIT ST., CUBAO QUEZON CITY, PHILIPPINES</p> */}
                {/* </div> */}

                <div class="email">
                  {/* Icon */}
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>developer@kdtdatasolution.com</p>
                </div>

                 {/* <div class="phone">
                  Icon 
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+639062334825</p>
                </div> */}
              </div>
            </Col>

            <Col lg={7} className="mt-5 mt-lg-0 d-flex align-items-stretch">
              <form action="" method="post" class="php-email-form">
                <Row>
                  <Col md={6} classname="form-group">
                    <label for="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      required
                    />
                  </Col>
                  <Col md={6} classname="form-group">
                    <label for="name">Your Email</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      required
                    />
                  </Col>
                  <div class="form-group">
                    <label for="name">Subject</label>
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">Message</label>
                    <textarea
                      class="form-control"
                      name="message"
                      rows="10"
                      required
                    ></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Contact;
