import React from "react";
// import Clients from "../components/Clients";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import WhyUs from "../components/WhyUs";
import Services from "./Services";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

function Home() {
  return (
    <>
      <Hero />
      {/* <Clients /> */}
      <AboutUs />
      <WhyUs />
      <Services/>
      <Portfolio/>
      <Contact/>
    </>
  );
}

export default Home;
