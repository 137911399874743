import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import HeroImg from "./../components/assets/img/hero-img.png";

function Hero() {
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <Container>
          <Row>
            <Col
              lg={6}
              className="d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            >
              <h1>Your Data Solutions Provider</h1>
              <h2>
                We are dedicated to provide efficient and cost effective data
                science/analytics, Information technology, and Professional
                design services.
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <Link
                  to="/documents/KDT_Company_Profile.pdf"
                  target="_blank"
                  role="button"
                  download
                >
                  <Button
                    variant="info"
                    className="ms-3 download-button  dl-button"
                  >
                    Read Our Portfolio
                  </Button>
                </Link>
                {/* <a
                  href={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Button variant="outline-none" className="watch-video">
                    <Icon.PlayCircleFill
                      size={30}
                      color={"#fff"}
                      className="mx-2 play-button"
                    />
                    Watch Video
                  </Button>
                </a> */}
              </div>
            </Col>
            <Col lg={6} className="order-1 order-lg-2 hero-img">
              <img src={HeroImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Hero;
