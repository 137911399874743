import React from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./../assets/img/LOGO-FINAL.svg";

function TopNavbar() {
  return (
    <>
      <Navbar bg="myBgCol" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} alt="" className="brand-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" className="nav-link">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="nav-link">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/services" className="nav-link">
                Services
              </Nav.Link>
              <Nav.Link as={Link} to="/portfolio" className="nav-link">
                Portfolio
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" className="nav-link">
                Contact
              </Nav.Link>
              <Link
                to="/documents/KDT_Company_Profile.pdf"
                target="_blank"
                role="button"
                download
              >
                <Button variant="info" className="ms-3 download-button  dl-button">
                  Read Our Portfolio
                </Button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default TopNavbar;
