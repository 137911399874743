import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
function AboutUs() {
  return (
    <>
      <section id="about" className="about">
        <Container>
          <div className="section-title">
            <h2> About Us</h2>
          </div>
          <Row className="content">
            <Col lg={6}>
              <p>
                KDT Network and Data Solution (KDT Solution) is a
                Philippine-based consultancy firm dedicated to providing
                efficient and cost-effective data science/analytics, Information
                technology, and Professional Design Services.
              </p>
              <Link to="/services">
                <Button variant="outline-info" className="btn-learn-more">
                  Learn More
                </Button>
              </Link>
            </Col>
            <Col lg={6} className="pt-4 pt-lg-0">
              <p>
                Combining these skillsets, we aim to be digital enablers for
                companies, institutions, and organizations.
              </p>
              <ul>
                <li>
                  <Icon.Check2All
                    color={"#47b2e4"}
                    size={20}
                    className="mx-2"
                  />
                  Information technology
                </li>
                <li>
                  <Icon.Check2All
                    color={"#47b2e4"}
                    size={20}
                    className="mx-2"
                  />
                  Data Science and Analytics
                </li>
                <li>
                  <Icon.Check2All
                    color={"#47b2e4"}
                    size={20}
                    className="mx-2"
                  />
                  Architecture and Engineering
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AboutUs;
