import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

function WhyUs() {
  return (
    <>
      <section id="why-us" className="why-us section-bg">
        <Container fluid>
          <Row>
            <Col
              lg={7}
              className="d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1"
            >
              <div className="content">
                <h3>
                  Why <strong>KDT Solution?</strong>
                </h3>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et <br /> dolore magna
                  aliqua. Duis aute irure dolor in reprehenderit
                </p> */}
              </div>
              <Accordion
                defaultActiveKey={["0"]}
                alwaysOpen
                className="accordion-list"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="subheading1">
                    <span>K</span>
                    nowledge of the industry
                  </Accordion.Header>
                  <Accordion.Body className="collapse-open">
                    Our company is composed of a team with key expertise in the
                    fields of Data Science and Analytics, Information
                    Technology, and A&E (Architecture and Engineering). Our
                    formula lies in this triumvirate.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="subheading1">
                    <span>D</span>
                    ata-Driven Solutions
                  </Accordion.Header>
                  <Accordion.Body>
                    Our company aims to become a digital enabler by providing
                    data-driven solutions to our clients. These solutions will
                    allow them to explore, explain, and organize their data with
                    the goal of formulating products, policies, and services.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="subheading1">
                    <span>T</span>
                    alent chemistry
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Our company is focused more on the chemical reactions of
                      our talents rather than having a large size. By working in
                      synergy, we leverage the potential of all members for
                      higher efficiency. In addition, we keep a lean
                      organization for quick communication and response.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col
              lg={5}
              className="align-items-stretch order-1 order-lg-2 img img-background"
            ></Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WhyUs;
