import "./App.css";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import TopNavbar from "./components/Navbar/TopNavbar";
import PortfolioDetails from "./components/PorfolioDetails";
import Project1Details from "./components/Project1Details";
import Project2Details from "./components/Project2Details";
import Project3Details from "./components/Project3Details";
import Project4Details from "./components/Project4Details";
import Project5Details from "./components/Project5Details";
import Project6Details from "./components/Project6Details";
import Project7Details from "./components/Project7Details";
import Project8Details from "./components/Project8Details";

function App() {
  return (
    <div className="App">
      <TopNavbar />
      <div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/portfolio" element={<Portfolio />}></Route>
          <Route path="/Services" element={<Services />}></Route>
          <Route path="/portfolio-details" element={<PortfolioDetails />}></Route>
          <Route path="/project1-details" element={<Project1Details />}></Route>
          <Route path="/project2-details" element={<Project2Details />}></Route>
          <Route path="/project3-details" element={<Project3Details />}></Route>
          <Route path="/project4-details" element={<Project4Details />}></Route>
          <Route path="/project5-details" element={<Project5Details />}></Route>
          <Route path="/project6-details" element={<Project6Details />}></Route>
          <Route path="/project7-details" element={<Project7Details />}></Route>
          <Route path="/project8-details" element={<Project8Details />}></Route>

        </Routes>
      </div>
    </div>
  );
}

export default App;
