import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Services() {
  return (
    <>
      <section id="services" class="services section-bg">
        <Container>
          <div class="section-title">
            <h2>KDT solution</h2>
            <p>is a provider of the following services:</p>
          </div>

          <Row>
            <Col xl={4} md={7} classname="d-flex align-items-stretch">
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bxl-dribbble"></i>
                </div>
                <h4>Data Science and Analytics</h4>
                <p>
                  We offer services in data science and analytics that help your
                  company derive the greatest value from your data.
                </p>
                <ul>
                  <li>Data Visualization</li>
                  <li>Business Intelligence</li>
                  <li>Big Data</li>
                  <li>AI and Machine Learning</li>
                </ul>
              </div>
            </Col>
            <Col
              xl={4}
              md={7}
              classname="d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bx-file"></i>
                </div>
                <h4>Architectural and Engineering Professional Services</h4>
                <p>
                  In partnership with JPF Design, we offer consultancy services
                  in the preparation of preliminary and detailed architectural
                  and engineering designs for the following type of projects:
                </p>
                <ul>
                  <li>Residential Projects</li>
                  <li>Institutional and Commercial Buildings</li>
                  <li>Low to mid-rise buildings</li>
                </ul>
              </div>
            </Col>
            <Col
              xl={4}
              md={7}
              classname="d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bx-tachometer"></i>
                </div>
                <h4>IT and Web Development Services</h4>
                <p>
                  We currently offer web development services as our solution
                  for the digital transformation of businesses and
                  organizations. Our capabilities are ranging from, but are not
                  limited to:
                </p>
                <ul>
                  <li>UI/UX Design</li>
                  <li>Front and Backend Development</li>
                  <li>Cloud Deployment</li>
                  <li>Database Management</li>
                </ul>
              </div>
            </Col>
            {/* <Col
              xl={3}
              md={6}
              classname="d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bx-layer"></i>
                </div>
                <h4>
                  <a href="/">Nemo Enim</a>
                </h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis
                </p>
              </div>
            </Col> */}
          </Row>
        </Container>
        <div class="container" data-aos="fade-up"></div>
      </section>
    </>
  );
}

export default Services;
