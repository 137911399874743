import React from "react";
import portfolio1 from "./../components/assets/img/portfolio1.jpg";
import portfolio2 from "./../components/assets/img/portfolio2.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import HQ from "./../components/assets/img/porfolioImg/HQ.png";
import MSI from "./../components/assets/img/porfolioImg/MSI.png";
import UAP from "./../components/assets/img/porfolioImg/UAP.png";
import MBY from "./../components/assets/img/porfolioImg/MBY.png";
import FA1 from "./../components/assets/img/porfolioImg/FA1.PNG";
import OC from "./../components/assets/img/porfolioImg/OC.png";
import BW from "./../components/assets/img/porfolioImg/BW.png";
import QCU from "./../components/assets/img/porfolioImg/QCU.png";


function Project7Details() {
  return <>

<main id="main">
        <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">

   


        <Link as={Link} to="/portfolio" >
        <p className="backp">Back</p>
        </Link>
       
    

       
            <h2>Project Details</h2>

        </div>
        </section>

        <section id="portfolio-details" class="portfolio-details">

            <Container>

                <Row
                gy={4}
                >
                    <Col
                    lg={8}
                    >

                    <div className="imgprojectcontainer">
                             <img className="imgp" src={OC} alt="" />
                    </div>

                    
                    </Col>

                    <Col
                    lg={4}
                    >
                              <div class="pfinfo mb-5">
                                <h3 class="mb-4"> Octagon  </h3>
                                <ul class="ulstyle">
                                    <li class="mb-3"><strong>Main Use Case</strong>: Recruitment Management System</li>
                                 
                         
                                    <li class="mb-3"><strong>Services Offered</strong>:
                                        <ul>
                                            <li>
                                            Job Opening Management System
                                            </li>
                                            <li>
                                            Applicant Tracking System 
                                            </li>
                                            <li>
                                            FAQs and Report System 
                                            </li>
                                            <li>
                                            Mobile Friendly Web Browser Version
                                            </li>
                                         
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="portfolio-description">
                                <h2 class="mb-4">Personal Projects of the Founder</h2>
                                <p>
                                As a brand-new company, we have developed zero (0) 
                                projects. Instead, we will show here the personal projects of 
                                the founder.</p>
                            </div>
                                    
                    
                    </Col>


                </Row>

            </Container>




        </section>



        </main>

        </>;
}

export default Project7Details;
