import React from "react";
import portfolio1 from "./../components/assets/img/portfolio1.jpg";
import portfolio2 from "./../components/assets/img/portfolio2.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";


function PortfolioDetails() {
  
  return <>


        <main id="main">
        <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">

   


        <Link as={Link} to="/portfolio" >
        <p className="backp">Back</p>
        </Link>
       
    

       
            <h2>Project Details</h2>

        </div>
        </section>

        <section id="portfolio-details" class="portfolio-details">

            <Container>

                <Row
                gy={4}
                >
                    <Col
                    lg={8}
                    >

                    <div className="imgprojectcontainer">
                             <img className="imgp" src={portfolio1} alt="" />
                    </div>

                    
                    </Col>

                    <Col
                    lg={4}
                    >
                              <div class="pfinfo mb-5">
                                <h3 class="mb-4">Project information</h3>
                                <ul class="ulstyle">
                                    <li class="mb-3"><strong>Category</strong>: Web design</li>
                                    <li class="mb-3"><strong>Client</strong>: ASU Company</li>
                                    <li class="mb-3"><strong>Project date</strong>: 01 March, 2020</li>
                                    <li class="mb-3"><strong>Project URL</strong>: <a href="#">www.example.com</a></li>
                                </ul>
                            </div>
                            <div class="portfolio-description">
                                <h2 class="mb-4">This is an example of portfolio detail</h2>
                                <p>
                                    Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.
                                </p>
                            </div>
                                    
                    
                    </Col>


                </Row>

            </Container>




        </section>



        </main>
      

        </>;
}

export default PortfolioDetails;
