import React from "react";
import AboutUs from "../components/AboutUs";

function About() {
  return <>

        <AboutUs />

        </>;
}

export default About;
